/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { formatNumber } from '@/utils/utils';
import dynamic from 'next/dynamic';
import { getResponsiveImage } from '@/componentWebs/NbmImageNew';
import EncodeUrl from '@/utils/encode';

// const { publicRuntimeConfig } = getConfig();
const OwlCarousel = dynamic(() => import('@/componentWebs/Global/OwlCarousel'), {
  ssr: false,
  loading: () => null,
});

@connect(({ webs: { dataAd } }) => ({
  dataAd,
}))
class Index extends Component {
  state = {
    dataIntroduce: [],
    isInternational: '0',
    hideNav: null,
  };

  componentDidMount() {
    const { isInternational } = this.state;
    this.fetch(isInternational);
    window.addEventListener('resize', this.resize.bind(this), { passive: true });
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize.bind(this));
  }

  fetch = isInternational => {
    try {
      const { data, dispatch } = this.props;
      this.setState({ loading: true });
      dispatch({
        type: 'webs/fetchHotel',
        payload: {
          filter: {
            status: '1',
            categoriesId: data.id,
            locations: { isInternational },
            categoriesProductsCatalogStatus: '1',
          },
        },
        callback: res => {
          this.setState({ loading: false });
          if (res && res.success) {
            const dataResults =
              res &&
              res.result &&
              res.result.list.length > 0 &&
              res.result.list.map(data => {
                return {
                  id: data.id,
                  travelHotelImage: data.travelHotelImage,
                  travelHotelName: data.travelHotelName,
                  provinces: data.provinces,
                  travelHotelStars: data.travelHotelStars,
                  travelHotelsRoomPrice: data.travelHotelsRoomPrice,
                  travelHotelsRoomDealPrice: data.travelHotelsRoomDealPrice,
                };
              }) || [];
            this.setState({
              dataIntroduce: (dataResults.length > 0 && dataResults) || [],
              isInternational,
            });
          } else {
            this.setState({
              dataIntroduce: [],
            });
          }
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleChangeTabs = International => {
    const { isInternational } = this.state;
    if (isInternational !== International) {
      this.fetch(International);
    }
  };

  renderStar = star => {
    const starArr = [];

    for (let i = 1; i <= 5; i += 1) {
      if (i <= star) {
        starArr.push(1);
      } else {
        starArr.push(0);
      }
    }

    return starArr;
  };

  handleDetail = item => {
    window.location.href = `/danhsach-khach-san/${EncodeUrl(item.travelHotelName)}/${item.id}`;
  };

  resize() {
    this.setState({ hideNav: window.innerWidth < 1024 });
  }

  render() {
    const { dataIntroduce, hideNav, isInternational, loading } = this.state;

    const { data } = this.props;
    // console.log('dataIntroduce: ', dataIntroduce);

    const template =
      data.templateLayouts && data.templateLayouts.templates && data.templateLayouts.templates[0];
    const imageResize =
      template && template.templateLayoutTemplates && template.templateLayoutTemplates.imagesResize;

    return (
      // Tin tức trang chủ
      <React.Fragment>
        {/* Khách sạn giá tốt nhất  */}
        <section className="bestHotel ">
          <div className="container">
            <div className="bestHotel__wrapper">
              <div className="section-header-products">
                <h3 className="title">
                  <img
                    src="/static/vietnamTickets/images/003-hotel.svg"
                    alt="hotel"
                    className="lazyload"
                    width="auto"
                    height="auto"
                  />
                  <span> {data.name}</span>
                </h3>
                <ul className="main-navigation">
                  <li className={`tab ${isInternational === '0' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('0')}>
                      <span>
                        <img
                          src="/static/vietnamTickets/images/NoPath - Copy (10).png"
                          alt="Nội địa"
                          className="lazyload"
                        />
                        Nội địa
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="202.068"
                        viewBox="0 0 202.068 51.844"
                      >
                        <path
                          id="Path_4163"
                          data-name="Path 4163"
                          d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z"
                          transform="translate(-23289.355 14986.643)"
                          fill="#eb0281"
                        />
                      </svg>
                    </a>
                  </li>
                  <li className={`tab ${isInternational === '1' && 'active'}`}>
                    <a onClick={() => this.handleChangeTabs('1')}>
                      <span>
                        <img
                          src="/static/vietnamTickets/images/earth.svg"
                          alt="Nội địa"
                          className="lazyload"
                        />
                        Quốc tế
                      </span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="202.068"
                        viewBox="0 0 202.068 51.844"
                      >
                        <path
                          id="Path_4163"
                          data-name="Path 4163"
                          d="M23289.355-14934.914s16.607-.354,22.189-13.742,10.816-37.814,43.607-37.934,70.2,0,70.2,0,26.232-1.732,36.559,18.467,7.742,28.393,29.514,33.324C23492.418-14934.945,23289.355-14934.914,23289.355-14934.914Z"
                          transform="translate(-23289.355 14986.643)"
                          fill="#eb0281"
                        />
                      </svg>
                    </a>
                  </li>
                  {/* etc. as many tabs as you'd like */}
                </ul>
              </div>
              <div className="bestHotel_tabs hidden-m-t">
                <ul className="bestHotel_tabs__list flex-a-c">
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/bestHotel.png"
                          alt="bestHotel"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Khách sạn mới nhất</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/bestPrice.png"
                          alt="bestPrice"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Giá hấp dẫn nhất</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/UPdate.png"
                          alt="UPdate"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Cập nhật hàng tuần</span>
                    </a>
                  </li>
                  <li>
                    <a>
                      <div className="bestHotel_tabs-imgWrappper">
                        <img
                          src="/static/vietnamTickets/images/special.png"
                          alt="special"
                          className="lazyload"
                          width="auto"
                          height="auto"
                        />
                      </div>
                      <span>Dành riêng cho bạn</span>
                    </a>
                  </li>
                </ul>
              </div>
              <Spin spinning={loading}>
                {!hideNav ? (
                  <div className="bestHotel-products">
                    <div className="row">
                      {dataIntroduce &&
                        dataIntroduce.length > 0 &&
                        dataIntroduce.map(item => (
                          <div className="col-xl-3 col-lg-4" key={item.id}>
                            <div className="box-product">
                              <a
                                onClick={() => this.handleDetail(item)}
                                className="box-product__img"
                              >
                                <img
                                  src={getResponsiveImage(
                                    item.travelHotelImage &&
                                    item.travelHotelImage[0] &&
                                    item.travelHotelImage[0].file,
                                    imageResize
                                  )}
                                  className="lazyload"
                                  alt={item.travelHotelName}
                                  title={item.travelHotelName}
                                />
                              </a>
                              <div className="box-product__detail">
                                <div className="box-product__place flex-a-c">
                                  <i
                                    className="fas fa-map-marker-alt"
                                    style={{ marginRight: '7px' }}
                                  />
                                  <span>{item.provinces && item.provinces.provinceName}</span>
                                </div>
                                <a
                                  onClick={() => this.handleDetail(item)}
                                  className="box-product__title"
                                >
                                  {item.travelHotelName}
                                </a>
                                {item.travelHotelStars && (
                                  <div className="box-product__rates">
                                    {this.renderStar(item.travelHotelStars) &&
                                      this.renderStar(item.travelHotelStars).length > 0 &&
                                      this.renderStar(item.travelHotelStars).map(x =>
                                        x === 1 ? (
                                          <img
                                            src="/static/vietnamTickets/images/star-vang.svg"
                                            alt="/star-vang"
                                            className="lazyload"
                                            width="auto"
                                            height="auto"
                                          />
                                        ) : (
                                          <img
                                            src="/static/vietnamTickets/images/star-xam.svg"
                                            alt="/star-xam"
                                            className="lazyload"
                                            width="auto"
                                            height="auto"
                                          />
                                        )
                                      )}
                                  </div>
                                )}

                                <div className="box-product__btnWrapper flex-b-c">
                                  <div className="wrapper-products">
                                    <div className="box-product__price-thruoght">
                                      {(item.travelHotelsRoomPrice &&
                                        formatNumber(item.travelHotelsRoomPrice)) ||
                                        '0'}
                                      đ
                                    </div>
                                    <div className="box-product__price">
                                      {(item.travelHotelsRoomDealPrice &&
                                        formatNumber(item.travelHotelsRoomDealPrice)) ||
                                        '0'}
                                      đ
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    className="btn box-product__btn"
                                    onClick={() => this.handleDetail(item)}
                                  >
                                    <span>Đặt ngay</span>
                                    <i className="fas fa-long-arrow-alt-right" />
                                  </button>

                                  {/* <div className="box-product__price">
                                    {(item.travelHotelsRoomDealPrice &&
                                      formatNumber(item.travelHotelsRoomDealPrice)) ||
                                      '0'}
                                    đ
                                  </div> */}
                                </div>
                              </div>

                              {item.travelHotelsRoomPrice !== item.travelHotelsRoomDealPrice && (
                                <div className="salseof-khachSan">
                                  -
                                  {Math.ceil(100 *
                                    ((Number(item.travelHotelsRoomPrice) -
                                      Number(item.travelHotelsRoomDealPrice)) /
                                      Number(item.travelHotelsRoomPrice)))}
                                  %
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="wrapper-carosl-khach-san">
                      {dataIntroduce && Array.isArray(dataIntroduce) && dataIntroduce.length > 0 && (
                        <OwlCarousel
                          key={`hotel_${isInternational}`}
                          className="carosel-star carosol-khach-san"
                          responsive={{
                            0: {
                              items: 1,
                            },
                            425: {
                              items: 1,
                            },
                            768: {
                              items: 2,
                            },
                            1000: {
                              items: 3,
                            },
                          }}
                          margin={30}
                          dots={false}
                          // autoplay
                          // autoplayHoverPause
                          // autoplayTimeout={3000}
                          slideBy={1}
                        // loop
                        >
                          {dataIntroduce.map(item => (
                            <div className="box-product" key={item.id}>
                              <a
                                onClick={() => this.handleDetail(item)}
                                className="box-product__img"
                              >
                                <img
                                  className="lazyload"
                                  src={getResponsiveImage(
                                    item.travelHotelImage &&
                                    item.travelHotelImage[0] &&
                                    item.travelHotelImage[0].file,
                                    imageResize
                                  )}
                                  alt={item.travelHotelName}
                                  title={item.travelHotelName}
                                />
                              </a>
                              <div className="box-product__detail">
                                <div className="box-product__place flex-a-c">
                                  <i
                                    className="fas fa-map-marker-alt"
                                    style={{ marginRight: '7px' }}
                                  />
                                  <span>{item.provinces && item.provinces.provinceName}</span>
                                </div>
                                <a
                                  onClick={() => this.handleDetail(item)}
                                  className="box-product__title"
                                >
                                  {item.travelHotelName}
                                </a>
                                {item.travelHotelStars && (
                                  <div className="box-product__rates">
                                    {this.renderStar(item.travelHotelStars) &&
                                      this.renderStar(item.travelHotelStars).length > 0 &&
                                      this.renderStar(item.travelHotelStars).map(x =>
                                        x === 1 ? (
                                          <img
                                            src="/static/vietnamTickets/images/star-vang.svg"
                                            alt="/star-vang"
                                            className="lazyload"
                                            width="auto"
                                            height="auto"
                                          />
                                        ) : (
                                          <img
                                            src="/static/vietnamTickets/images/star-xam.svg"
                                            alt="/star-xam"
                                            className="lazyload"
                                            width="auto"
                                            height="auto"
                                          />
                                        )
                                      )}
                                  </div>
                                )}

                                <div className="box-product__btnWrapper flex-b-c">
                                  <button
                                    type="button"
                                    onClick={() => this.handleDetail(item)}
                                    className="btn box-product__btn"
                                  >
                                    <span>Đặt ngay</span>
                                    <i className="fas fa-long-arrow-alt-right" />
                                  </button>
                                  <div className="wrapper-products">
                                    <div className="box-product__price-thruoght">
                                      {(item.travelHotelsRoomPrice &&
                                        formatNumber(item.travelHotelsRoomPrice)) ||
                                        '0'}
                                      đ
                                    </div>
                                    <div className="box-product__price">
                                      {(item.travelHotelsRoomDealPrice &&
                                        formatNumber(item.travelHotelsRoomDealPrice)) ||
                                        '0'}
                                      đ
                                    </div>
                                  </div>
                                </div>
                                {item.travelHotelsRoomPrice !== item.travelHotelsRoomDealPrice && (
                                  <div className="salseof-khachSan">
                                    -
                                    {Math.ceil(100 *
                                      ((Number(item.travelHotelsRoomPrice) -
                                        Number(item.travelHotelsRoomDealPrice)) /
                                        Number(item.travelHotelsRoomPrice)))}
                                    %
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </OwlCarousel>
                      )}
                    </div>
                  </>
                )}
              </Spin>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Index;
